.form-control, .form-control:focus {
    border: none !important;
    border-radius: 0 !important;
}

.form-control {
    border-bottom: 1px solid #ced4da !important;
}

.form-control:focus {
    box-shadow: none !important;
    border-bottom: .125rem solid #029b90 !important;
}

textarea {
    line-height: 24px;
}
