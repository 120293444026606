a {
    color: #61dafb !important;
}

a.custom-style {
    color: inherit !important;
    text-decoration: none !important;
}

.clickable {
    cursor: pointer;
}

.line-height-1 {
    line-height: 1.1em;
}

.text-small {
    font-size: 80%;
}

.section-content {
    /*background-color: #22262d;*/
    border: 1px solid #22262d;
    border-radius: 0 0 5px 5px;
    border-top: none;
}

.section-header, .page-header {
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
    border-radius: 5px;
}

.section-header {
    background-color: #22262d;
    color: #d4f2f9;
}

.section-header-btn {
    padding: 8px 10px;
    border-radius: 25px;
    -webkit-box-shadow: 0px 0px 15px 0px rgb(255 255 255 / 80%);
    -moz-box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.8);
    box-shadow: 0px 0px 15px 0px rgb(255 255 255 / 80%);
}

.section-header-top {
    border-radius: 5px 5px 0 0;
}