.header {
    padding: 15px;
}

.header, nav, .nav-link, .navbar-brand, .navbar-toggler, .navbar-toggler-icon {
    background-color: #22262d;
    color: #61dafb !important;
}

.navbar {
    padding: 0 !important;
}

.navbar-toggler {
    border-color: #61dafb !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2897, 218, 251, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}