.btn-default {
    border: 1px solid #cac3c3 !important;
}

.btn-light {
    border: 1px solid #37b8c8 !important;
    background-color: white !important;
}

.btn-light, .btn-light a {
    color: #37b8c8 !important;
}

.btn-danger {
    border: 1px solid #e80fb6 !important;
    background-color: white !important;
    color: #e80fb6 !important;
}

.icon-btn {
    border-radius: 25px !important;
}

.nav-btn {
    display: inline-block;
}