.rpe {
    cursor: pointer;
}

.rpe-0 {
    background-color: #e4f2ed;
}

.rpe-1 {
    background-color: #caf4e6;
}

.rpe-2 {
    background-color: #a5e6d1;
}

.rpe-3 {
    background-color: #6de4bd;
}

.rpe-4 {
    background-color: #6ac594;
}

.rpe-5 {
    background-color: #dfe49f;
}

.rpe-6 {
    background-color: #dfc371;
}

.rpe-7 {
    background-color: #dbac10;
}

.rpe-8 {
    background-color: #db6d10;
}

.rpe-9 {
    background-color: #dc6767;
}

.rpe-10 {
    background-color: #d82f2f;
}

.rpe-badge {
    width: fit-content;
    text-align: center;
    border-radius: 3px;
}

.rpe-not-selected {
    opacity: 0.5;
}

.rpe-selected {
    font-weight: bold;
}

.rpe-selector {
    display: flex;
    flex-wrap: nowrap;
}