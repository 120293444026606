.workout-icon {
    border-radius: 30px;
    padding: 12px 10px 12px 10px;
    width: fit-content;
    width: 61px;
    text-align: center;
    color: #0b5d6f;

    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    /*box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);*/
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);
}

.w-icon-gym, .w-tile-gym {
    box-shadow: 0 0 5px 0 #2ea9c5;
}

.w-icon-gym {
    /*border: 1px solid #14b5d0;
   background-color: #3cd6ef;*/
    background-color: #fff;

    color: #2ea9c5;
}

.w-icon-walking, .w-tile-walking {
    box-shadow: 0 0 5px 0 #fd6bf3;
}

.w-icon-walking {
    /*border: 1px solid #ce18a7;
    background-color: #eb0defa6;*/
    background-color: #fff;
    color: #fd6bf3;
}

.workout-tile {
    padding: 10px;
    border-radius: 5px;
    margin: 5px;

    background-color: white;
}

.w-tile-walking {
    /*border: 1px solid #f7dcf1;
    background-color: #fdeefc;*/
}

.w-tile-gym {
    /*border: 1px solid #d4f2f9;
    background-color: #e3f9fb;*/
}

.w-icon-other {
    color: #d8b828;
}

.w-icon-other, .w-tile-other {
    box-shadow: 0 0 5px 0 #d8b828;
}

.w-tile-other {
    /*border: 1px solid #f1e2b9;
    background-color: #fffbe9;*/
}

/* Desktop */

.w-tile-d {
    height: 170px;
}

.w-tile-d .workout-icon {
    padding: 2px 5px 3px 5px;
    width: 28px;
    margin-right: 5px;
}

.w-tile-d .w-date {
    margin-bottom: 10px;
}

/* Mobile */

