.calendar-btn {
    background-color: #22262d;
    border: 1px solid #61dafb;
}

.calendar-btn, .calendar-btn:hover {
    color: #61dafb;
}

.calendar-btn-right, .calendar-btn-left {
    width: 100%;
    border-radius: 0;
}

/*.calendar-btn-right {
    border-radius: 0 5px 0 0;
}

.calendar-btn-left {
    border-radius: 5px 0 0 0;
}*/

.calendar-col-center {
    background-color: #22262d;
    border-top: 1px solid #61dafb;
    border-bottom: 1px solid #61dafb;
    color: #d4f2f9;
    line-height: 31px;
    text-align: center;
}

.calendar-col-left, .calendar-col-right  {
    padding-right: 0;
    padding-left: 0;
}

/*.weekday {
    color: #d4f2f9;
}*/

.weekday, .day-workouts {
    /*width: 14%;*/
    display: inline-block;
    /* color: #22262d; */
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
}

.day-workouts {
    vertical-align: top;
}

.no-workouts {
    /*background-color: #dfdcdc78;*/
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
   /* border: 1px solid #d5d3d3;*/
    box-shadow: 0 0 5px 0 #d5d3d3;
}