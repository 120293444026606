.last-move {
    background-color: rgb(248 248 255 / 56%) !important;;
}

.move-group-number {
    padding: .275rem .55rem;
    border-radius: 25px;
    border: 1px solid grey;
    font-size: 70%;
    top: -3px;
    position: relative;
}

.superset-1, .superset-2, .superset-3 {
    border-radius: 5px;
    border-style: solid;
    border-width: 4px;
}

.superset-1 {
    border-color: #19c27647;
}

.superset-2 {
    border-color: #4519c247;
}

.superset-3 {
    border-color: #ff980052;
}

/*.move-group-number-set-1 {
    border-color: #19c276ba;
    background-color: #19c27647;
}

.move-group-row-1 {
    border-left: 2px solid #19c27647;
}

.move-group-number-set-2 {
    border-color: #4519c28c;
    background-color: #4519c247;
}

.move-group-row-2 {
    border-left: 2px solid #4519c247;
}

.move-group-number-set-3 {
    border-color: #ff9800;
    background-color: #ff980052;
}

.move-group-row-3 {
    border-left: 2px solid #ff9800;
}*/